<template>
  <div class="row">
    <div class="col-4">
      {{ title }}:
    </div>

    <div class="col-8">
      <v-hover
          v-slot="{hover}"
          close-delay="170">
        <v-row
            @click="toggleClick()">
          <v-col cols="8">
            <!-- Display Field-->
            <span v-if="!isEdit ">
              <!-- show clickable icon if set in btn.icon-->
                    <v-icon
                        @click="openEntry(btn.entity, btn.id)"
                        v-if="btn && value !== 'Hochzeit wurde gelöscht' && editBtn && editBtn.autoComplete && editBtn.autoComplete.value"
                        class="pe-1"
                        color="primary"
                    >
                {{ btn.icon }}
              </v-icon>
              <!-- Ende show icon if set in btn.icon-->


              <!-- Shows actual Value of the Field -->

              <b v-if="title === 'Kategorie' && value.name !== ''">
                <v-icon v-if="value.icon" color="secondary" size="20" class="pe-1">
                  {{ value.icon }}
                </v-icon>  {{ value.name }}
              </b>
              <b v-else-if="entity === 'invoice' && editBtn.row==='status' && !readonly">
                <span v-if="value === 0">In Erstellung</span>
                <span v-if="value === 1">versendet</span>
                <span v-if="value === 2">bezahlt</span>
                <span v-if="value === 3">storniert</span>
              </b>
              <b v-else-if="entity === 'invoice' && editBtn.row==='status'">
                  <v-icon>
                    mdi-lock
                  </v-icon>
                <span v-if="value === 0" style="color:grey">In Erstellung</span>
                <span v-if="value === 1" style="color:grey">versendet</span>
                <span v-if="value === 2" style="color:grey">bezahlt</span>
                <span v-if="value === 3" style="color:grey">storniert</span>
                </b>
              <b v-else-if="title === 'Preisklasse'">
                <span v-if="value === 1">€</span>
                <span v-if="value === 2">€€</span>
                <span v-if="value === 3">€€€</span>
              </b>
              <b v-else-if="editBtn.type === 'float' && value">
                {{ replaceDot(value) }}
              </b>
              <b v-else-if="editBtn.type === 'date' && editBtn.entity === 'customFieldValue' && value">{{ getDate(value) }}</b>
              <b v-else-if="editBtn.type === 'autoComplete' && editBtn.autoComplete.color">
                        <span :style="'border-left: 7px solid '+editBtn.autoComplete.color" class="ps-1">{{
                            value
                          }}</span>
              </b>
              <b v-else-if="editBtn.type === 'multipleAutoComplete'" class=" mt-0 pt-0">
                <b v-for="(val, index) of value" :key="val.name" class=" mt-0 pt-0">
                  <div v-if="index!==0" class="mt-2 mb-2"></div>
                    <span v-if="getColor(val) || getColor(val.name)" :style="'background-color: '+ getColor(val)" class="rounded-xl  ps-2 pe-2 pa-1 ms-0  text--white">
                        <span class="font-weight-light mt-0 pt-0 white--text" v-if="val.name">{{ val.name }}</span>
                        <span v-else class="white--text font-weight-light">{{ val }}</span>
                    </span>
                   <span v-else>
                     <span v-if="index!==0">, </span>
                     <span v-if="val.name">{{ val.name }}</span>
                     <span v-else>{{ val }}</span>
                  </span>
                </b>
              </b>
              <b v-else-if="editBtn.type==='link'"><a target="_blank" :href="value"><v-icon color="primary" v-if="value">mdi-link</v-icon>{{ value }}</a></b>
              <span v-else>
                <b v-if="!readonly">{{ value }}</b>
                <b v-else style="color:grey">
                  <v-icon>
                    mdi-lock
                  </v-icon>
                  {{ value }}
                </b>
               </span>
            </span>
            <!-- Ende Display Field-->

            <!-- Edit Field-->
            <div
                v-else-if="isEdit"
            >
              <!-- Edit Field if is text-Feld-->
              <v-text-field
                  v-if="editBtn.type === 'string' || editBtn.type === 'link'|| editBtn.type === undefined || editBtn.type === null || editBtn.type === '' || editBtn.type === 'text'"
                  :value="value"
                  v-model="value">
              </v-text-field>
              <!-- Ende Edit Field if is text-Feld-->

              <!-- Edit Field if is number-Feld-->
              <v-text-field
                  v-if="editBtn.type === 'number' || editBtn.type === 'float' || editBtn.type === 'double'"
                  :value="value"
                  v-model="value"
                  type="number">
              </v-text-field>
              <!-- Edit Field if is number-Feld-->

              <!-- Edit Field if is textarea-Feld-->
              <v-textarea
                  v-if="editBtn.type === 'textarea'"
                  :value="value"
                  v-model="value"
              ></v-textarea>
              <!-- Ende Edit Field if is textarea-Feld-->

              <!-- Edit Field if is autoComplete-Feld-->
              <v-autocomplete
                  v-else-if="editBtn.type === 'autoComplete'"
                  :data="editBtn.autoComplete.value"
                  :label="'Auswählen'"
                  :items="editBtn.autoComplete.items"
                  v-model="editBtn.autoComplete.value"
                  @change="value=editBtn.autoComplete.value"
              ></v-autocomplete>
              <!-- Ende Edit Field if is autoComplete-Feld-->

              <!-- Edit Field if is multipleAutoComplete-Feld-->
              <v-autocomplete
                  v-else-if="editBtn.type === 'multipleAutoComplete'"
                  :data="editBtn.autoComplete.value"
                  :label="'Auswählen'"
                  :items="editBtn.autoComplete.items"
                  v-model="editBtn.autoComplete.value"
                  @change="value=editBtn.autoComplete.value"
                  multiple
                  small-chips
                  deletable-chips
              ></v-autocomplete>
              <!-- Ende Field if is multipleAutoComplete-Feld-->


              <!-- Edit Field if is date-Feld-->
              <div v-else-if="editBtn.type === 'date'"
              >
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="value"
                        :label="'Datum'"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="value"
                      max-width="700"
                      locale="de-de"
                      @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <!-- Ende Edit Field if is time-Feld-->
              <div v-else-if="editBtn.type === 'time'"
              >
                <TimePicker
                    :data="{
                      title: '',
                      value: getJustTime(value),
                      id: 3,
                      type: 'time',
                    }"
                    @sendData="setTime"
                ></TimePicker>
              </div>
            </div>
            <!-- Ende Edit Field-->
          </v-col>

          <!-- Edit Buttons -->
          <v-col>
            <!-- Edit Button save -->
            <v-icon
                color="primary"
                class="ms-3"
                v-if="isEdit && editBtn"
                @click="editEntry(editBtn.entity, editBtn.id, value, editBtn.row)"
            >
              mdi-check
            </v-icon>
            <!-- Ende Edit Button save -->

            <!-- Edit Button cancel -->
            <v-icon
                v-if="isEdit && editBtn"
                @click="closeEdit()"
                class="ms-3"
            >
              mdi-cancel
            </v-icon>
            <!-- Ende Edit Button cancel -->

            <!-- Edit Button startEdit -->
            <v-icon
                v-if="(clicked && !isEdit || hover && !isEdit && editBtn || !value && !isEdit && editBtn) && title !== 'Erstellt am' && !readonly"
                color="primary"
                size="20"
                class="scale fadeInDelay"
                @click="openEdit">
              mdi-lead-pencil
            </v-icon>
            <!-- Ende Edit Button startEdit -->
          </v-col>
          <!-- Ende Edit Buttons -->
        </v-row>
      </v-hover>
    </div>
  </div>
</template>

<script>/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import {date2String, dotToKomma} from "@/helper/helper";


const TimePicker = () => import("@/components/dialog/fields/TimePicker");

export default {
  name: "KeyValue",
  data() {
    if (this.editBtn.type === 'date') {
      return {
        clicked: false,
        menu: false,
        isEdit: false
      }
    } else {
      return {
        newValue: this.value,
        clicked: false,
        menu: false,
        isEdit: false,
        menu2: false
      }
    }
  },
  components: {TimePicker},
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: [String, Number, Object, Array],
      default() {
        return ''
      }
    },
    btn: {
      type: Object,
      default() {
        return {}
      }
    },
    editBtn: {
      type: Object,
      default() {
        return {}
      }
    },
    customFieldValueId: {
      type: String,
      default() {
        return null
      }
    },
    readonly:{
      type: Boolean,
      default() {
        return false
      }
    },
    entity: {
      type: String,
      default() {
        return ''
      }
    },
  },
  methods: {
    getJustTime(time) {
      return time.split(' ')[0]
    },
    setTime(payload) {
      this.value = payload.value
    },
    openEntry(entity, id) {
      this.$emit('openEntry', {entity, id})
    },
    getColor(name) {
      for (let field of this.editBtn.autoComplete.items){
        if (field.text === name) {
          if (field.color) {
            return field.color
          }
        }
      }

    },
    openEdit() {
      if (this.editBtn.type === 'date' && this.editBtn.date !== null) {
        this.value = this.editBtn.date
      }
      this.isEdit = true
    },
    editEntry(entity, id, newValue, row) {
      this.isEdit = false

      this.$emit('editEntry', {entity, id, newValue, row, customFieldValueId: this.customFieldValueId})
    },
    closeEdit() {
      if (this.editBtn.type === 'date') {
        this.value = this.getStringDate(this.editBtn.oldValue)
      }
      if (this.editBtn.type === 'time') {
        this.value = this.editBtn.oldValue
      }
      this.isEdit = false
    },
    toggleClick() {
      this.clicked = !this.clicked;
    },
    getStringDate(date) {
      return date2String(date)
    },
    getDate(date) {
      let datum = Date.parse(date)
      return date2String(datum)
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
  }
}
</script>
